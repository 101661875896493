<template>
  <div>
    <div class="search-box">
      <el-input
        placeholder="请输入项目名称关键字查询"
        v-model="projectTitle"
        style="width: 300px"
      >
        <el-button
          slot="append"
          @click="getDataList()"
          icon="el-icon-search"
        ></el-button>
      </el-input>
      <el-button type="primary" @click="addProject">新增项目</el-button>
    </div>
    <div class="project-list">
      <div class="list-item">
        <div class="type-name">真实世界研究</div>
        <div class="title">
          基于炎症免疫网络调控的新安固本培元法之方证对应改善COPD患者感受的分子机制研究
        </div>
        <div style="width: 20%; padding-right: 20px">
          国家自然科学基金区域创新重点项目
        </div>
        <div style="width: 20%">
          <div>入组进度：</div>
          <el-progress :percentage="50"></el-progress>
        </div>
        <div class="btns" style="width: 20%">
          <el-button type="primary">病例填写</el-button>
          <el-button type="">进入查看</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增项目"
      :visible.sync="visible"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        inline
        label-width="100"
        :model="dataForm"
        :rules="dataRules"
        class="add-project-form"
      >
        <el-form-item label="项目名称">
          <el-input
            v-model="dataForm.projectName"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目类别">
          <el-input
            v-model="dataForm.projectName"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目来源">
          <el-input
            v-model="dataForm.projectName"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectTitle: "",
      visible: false,
      dataForm: {},
      dataRules: {},
    };
  },
  methods: {
    getDataList() {},
    addProject() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.project-list {
  .list-item {
    padding: 30px 0px 30px 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    div {
      display: flex;
      justify-content: center;
    }
    .type-name {
      width: 10%;
      background: #0099ff;
      border-radius: 3px;
      height: 20px;
      padding: 0 10px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-weight: bold;
      font-size: 16px;
      width: 40%;
      padding: 0 40px 0 20px;
    }
    .btns {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.add-project-form {
  width: 480px !important;
  margin: 0 auto;
  /deep/.el-form-item {
    width: 480px !important;
  }
  .el-form-item__content {
    width: 100%;
  }
}
</style>
